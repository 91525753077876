<template>
  <footer class="app-footer">
    <frameContent>
      <!-- 中部 -->
      <template #center>
        <div class="footer-article">
          <div class="footer-content">
            <ul class="footer-phone">
              <li class="title">联系方式</li>
              <li>电话：020-32030256</li>
              <li>Email：business@xunlongai.com</li>
            </ul>
            <div class="footer-title">
              <div class="placeholder-box"></div>
              <ul>
                <li @click="NavChange('Home')">首页</li>
                <li @click="NavChange('About')">关于</li>
              </ul>
              <ul>
                <li @click="NavChange('Campus')">产品及解决方案</li>
                <li @click="NavChange('Case')">经典案例</li>
              </ul>
              <ul>
                <li @click="NavChange('Innovate')">技术创新</li>
                <li @click="NavChange('Contact')">联系我们</li>
              </ul>
            </div>
          </div>

          <div class="login-copyright">
            © 2021-2023 www.zkxunlong.com 版权所有 &nbsp;<a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              >ICP证：粤ICP备2021153177号</a
            >

            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202001838"
              style="
                display: inline-block;
                text-decoration: none;
                vertical-align: middle;
              "
            >
              <img
                src="@/assets/PublicSecurity.png"
                style="
                  float: left;
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  vertical-align: middle;
                "
              />
              <p style="float: left; margin: 0px 0px 0px 5px">
                粤公网安备 44011202001838号
              </p>
            </a>
            &nbsp;讯龙(广东)智能科技有限公司
          </div>
        </div>
      </template>
    </frameContent>
  </footer>
</template>

<script>
import frameContent from '@/components/frameContent.vue'
export default {
  name: 'footer',
  components: {
    frameContent
  },
  methods: {
    NavChange (val) {
      this.$store.commit('ActiveNameChange', val)
    }
  }
}
</script>

<style lang="less" scoped>
.app-footer {
  // position: absolute;
  // bottom: 0px;
  width: 100%;
  height: 177px;
  background: #122233;
  padding-top: 36px;
  .footer-article {
    .footer-content {
      height: 108px;
      display: flex;
      justify-content: space-between;
      .footer-phone {
        flex: 1;
        text-align: left;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.85);
          margin-bottom: 4px;
        }
        li {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.65);
        }
        li:nth-child(1) {
          line-height: 22px;
          margin-top: 0px;
        }
      }
      .footer-title {
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
        ul li:nth-child(2n) {
          text-align: left;
          margin-top: 12px;
        }
        ul li {
          cursor: pointer;
          &:hover{
             text-decoration: underline;
             color: rgba(255, 255, 255);
          }
        }
        .placeholder-box {
          flex: 0.4;
        }
      }
    }
    .login-copyright {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.45);
      line-height: 17px;
      padding-bottom: 13.5px;
      a,
      p {
        color: rgba(255, 255, 255, 0.45);
        font-size: 12px;
        font-weight: 400;
        text-indent: 0em;
      }
    }
  }
}
</style>
