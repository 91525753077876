<template>
  <div>
    <div class="app-home" ref="appHome">
      <div class="home-article">
        <div class="article-block">
          <el-carousel
            trigger="click"
            height="47.56rem"
            @change="carouselChange"
          >
            <el-carousel-item v-for="(item, index) in carouselArr" :key="index">
              <img
                class="article-image"
                :src="item.url"
                alt=""
                @click="NavChange(item.name)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <article>
        <div class="home-about">
          <div class="home-about-content">
            <ul>
              <li>关于讯龙科技</li>
              <li>
                讯龙（广东）智能科技有限公司，致力于通过人工智能创新中的机器视觉和深度学习技术积累，为传统制造业赋能，提升公共服务水平。
                在机器视觉领域中，我司已经创新性地发展出了人脸识别、姿态识别、瑕疵识别，以及危险动作识别、表情分析和轨迹分析等关键技术。在智慧教育、智慧体育、智慧安防，以及智慧交通、智慧养老和智慧旅游等领域积累了数十项专利及科研成果。
              </li>
              <li>
                <div style="cursor: pointer" @click="NavChange('About')">
                  更多介绍<i class="el-icon-arrow-right"></i>
                </div>
              </li>
            </ul>
          </div>
          <div class="home-about-image">
            <img src="@/assets/articleImage.png" alt="" />
          </div>
        </div>
        <div class="home-icon">
          <ul>
            <li>
              <div class="Image">
                <!-- <img src="@/assets/icon3.png" alt="" /> -->
              </div>
              <div class="home-icon-content">
                <h4>图像识别</h4>
                <span
                  >通过视频识别技术完成特定场景下
                  <br />
                  的人物，物体，行为识别，提升监
                  <br />管分析效率</span
                >
              </div>
            </li>
            <li>
              <div class="Image">
                <!-- <img src="@/assets/icon2.png" alt="" /> -->
              </div>
              <div class="home-icon-content">
                <h4>人脸识别</h4>
                <span
                  >实时人脸检测技术。人脸表情分析<br />
                  技术。提供需求场景下的及时人脸<br />识别及分析</span
                >
              </div>
            </li>
            <li>
              <div class="Image">
                <!-- <img src="@/assets/icon1.png" alt="" /> -->
              </div>
              <div class="home-icon-content">
                <h4>车辆识别</h4>
                <span>助力城市智慧交通建设，<br />为行业赋能<br />&nbsp;</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="home-Product">
          <div class="Product-title">
            <h1>产品介绍</h1>
            <div class="Product-content">
              <el-carousel trigger="click" height="19.75rem" arrow="always">
                <el-carousel-item
                  v-for="(item, index) in productArr"
                  :key="index"
                >
                  <div class="Product-content-carousel">
                    <div
                      class="Product-content-carousel-item"
                      v-for="(val, i) in item"
                      :key="i"
                    >
                      <div class="Image"><img :src="val.url" alt="" /></div>
                      <div class="content">
                        <h3>{{ val.name }}</h3>
                        <div
                          class="Product-content-btn"
                          @click="NavChange('Banner1')"
                        >
                          更多介绍<i class="el-icon-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="home-partner">
          <div class="partner-border-content">
            <div class="partner-border-content-tilte">合作伙伴</div>
            <ul>
              <li>
                <div class="Image">
                  <img src="@/assets/huaNan.png" />
                  <div class="content">华南师范大学</div>
                </div>
              </li>
              <!-- <li>
                <div class="Image"><img src="@/assets/shiFan.png" /></div>
                <div class="content">北京师范大学</div>
              </li> -->
              <!-- <li>
                <div class="Image"><img src="@/assets/zhongKe.png" /></div>
                <div class="content">中国中科院</div>
              </li> -->
              <!-- <li>
                <div class="Image"><img src="@/assets/shiDa.png" /></div>
                <div class="content">广东师大智能科技有限公司</div>
              </li> -->
              <!-- <li>
                <div class="Image"><img src="@/assets/aLi.png" /></div>
                <div class="content">阿里云</div>
              </li> -->
              <li>
                <div class="Image"><img src="@/assets/HUAWEI.png" /></div>
                <div class="content">华为</div>
              </li>
              <!-- <li>
                <div class="Image"><img src="@/assets/baiDu.png" /></div>
                <div class="content">百度</div>
              </li> -->
              <li>
                <div class="Image"><img src="@/assets/dongRuan.png" /></div>
                <div class="content">广东东软学院</div>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import banner1 from '@/assets/Campus/Banner.png'
import banner2 from '@/assets/Park/Banner.png'
import banner3 from '@/assets/Sports/Banner.png'

import product1 from '@/assets/Home/product1.png'
import product2 from '@/assets/Home/product2.png'
import product3 from '@/assets/Home/product3.png'
import Image1 from '@/assets/Home/Image1.png'
import Image2 from '@/assets/Home/Image2.png'
import Image3 from '@/assets/Home/Image3.png'

export default {
  name: 'Home',
  data () {
    return {
      carouselArr: [
        { url: banner1, type: false, name: 'Campus' },
        { url: banner2, type: true, name: 'Park' },
        { url: banner3, type: true, name: 'Sports' }
      ],

      productArr: [
        [
          { url: product1, name: '请假一体终端' },
          { url: product2, name: '人脸识别测温一体机' },
          { url: product3, name: '闸机通道' }
        ],
        [
          { url: Image1, name: '访客终端' },
          { url: Image2, name: 'AI智能算法终端' },
          { url: Image3, name: '讯龙人脸/姿态AI识别摄像机' }
        ]
      ],
      isType: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
    this.$store.commit('totalChange', {
      isType: this.carouselArr[0].type,
      isHome: true
    })
  },

  methods: {
    carouselChange (val) {
      this.isType = this.carouselArr[val].type
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop === 0) {
        this.$store.commit('totalChange', {
          isType: this.isType,
          isHome: true
        })
      }
    },
    NavChange (val) {
      this.$store.commit('ActiveNameChange', val)
    },
    scrollToTop () {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 0) {
        this.$store.commit('totalChange', { isType: false, isHome: false })
      } else {
        this.$store.commit('totalChange', {
          isType: this.isType,
          isHome: true
        })
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollToTop)
  }
}
</script>

<style scoped lang="less">
.app-home {
  .home-article {
    position: relative;
    height: 654px;
    .article-block {
      position: absolute;
      width: 100%;
      height: 761px;
      top: -107px;
      z-index: 1;
      .article-image {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  article {
    background: #f9f9f9;
    .home-about {
      max-width: 1180px;
      min-width: 895px;
      margin: 0 auto;
      padding: 128px 0px 120px;
      .home-about-content {
        margin-bottom: 40px;
        ul {
          display: flex;
          align-items: center;
          li {
            &:nth-child(1) {
              margin-right: 48px;
              font-size: 16px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.85);
            }
            &:nth-child(2) {
              margin-left: 48px;
              position: relative;
              max-width: 852px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
              text-align: left;
              &::before {
                left: -48px;
                position: absolute;
                content: "";
                width: 1px;
                height: 72px;
                background-color: #9a9a9a;
              }
            }
            &:nth-child(3) {
              // overflow: hidden;
              margin-left: 48px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.85);
              div {
                width: 87px;
                height: 25px;
                border-radius: 2px;
                border: 1px solid #274db4;
                line-height: 25px;
                font-size: 12px;
                font-weight: 400;
                color: #274db4;
                &:hover {
                  font-weight: 400;
                  color: #ffffff;
                  background-color: #274db4;
                }
              }
            }
          }
        }
      }
      .home-about-image {
        height: 243px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .home-icon {
    padding: 56px 0px 76px;
    background-color: #f3f5f8;
    box-shadow: inset 0px 1px 30px 0px rgba(170, 189, 225, 0.1);
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1180px;
      min-width: 890px;
      margin: 0 auto;
      li {
        margin-top: 30px;
        display: flex;
        align-items: center;
        width: 380px;
        height: 136px;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px rgba(56, 61, 73, 0.1);
        background-image: url("../assets/iconBackgroundImage.png");
        background-repeat: no-repeat;
        background-size: 380px 136px;
        padding: 0px 36px;
        img {
          width: 64px;
          height: 64px;
          margin-right: 24px;
        }
        .home-icon-content {
          // text-align: left;
          width: 100%;
          text-align: center;
          h4 {
            font-size: 20px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
            margin-bottom: 8px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 20px;
          }
        }
      }
    }
  }
  .home-Product {
    padding: 120px 0px;

    h1 {
      font-size: 48px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 42px;
    }
    .Product-content {
      max-width: 1390px;
      margin: 0 auto;
      min-width: 895px;
      .Product-content-carousel {
        display: flex;
        padding: 0px 101px;
        .Product-content-carousel-item {
          padding-left: 40px;
          &:nth-child(1) {
            padding-left: 0px;
          }
          .Image {
            max-width: 368px;
            height: 240px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: middle;
          }
          .content {
            margin-top: 16px;
            text-align: left;
            font-size: 20px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
          }
          .Product-content-btn {
            margin-top: 8px;
            width: 87px;
            height: 25px;
            border-radius: 2px;
            border: 1px solid #274db4;
            font-size: 12px;
            text-align: center;
            line-height: 25px;
            font-weight: 400;
            color: #274db4;
            cursor: pointer;
            &:hover{
              font-weight: 400;
              color: #ffffff;
              background-color: #274db4;
            }
            i {
              // margin-left: 9px;
            }
          }
        }
      }
    }
  }
  .home-partner {
    background-color: #bd1515;
    height: 600px;
    background: url("../assets/HomeBackgroundImage.png") no-repeat;
    padding: 136px 0px;
    background-size: 100% 600px;
    display: block;
    .partner-border-content {
      display: block;
      margin: 0 auto;
      background: rgba(255, 255, 255, 0.11);
      box-shadow: 0px 2px 30px 0px rgba(0, 71, 139, 0.3);
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(50px);
      max-width: 1180px;
      min-width: 895px;
      height: 328px;
      .partner-border-content-tilte {
        margin: 27px 0px 28px;
        font-size: 48px;
        font-weight: 600;
        color: #ffffff;
      }
      ul {
        display: flex;
        text-align: center;
        align-content: center;
        flex-flow: row wrap;
        justify-content: center;
        li {
          flex: 0 50 20.09%;
          margin-bottom: 20px;
          min-width: 195px;
          &:nth-child(n + 5) {
            margin-top: 29px;
          }
          .content {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
        }
        img {
          width: 102px;
          height: 102px;
          object-fit: cover;
        }
      }
    }
  }
}
/deep/.app-home .el-carousel__indicators--horizontal {
  opacity: 0;
}

// .app-home .Product-content {
//   height: 315px;
// }
</style>
