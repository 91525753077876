<template>
  <div class="app-contact">
    <div class="contact-box">
      <div class="contact-title">联系我们</div>
      <div class="phone">
        <div>
          <div>地址：</div>
          <span>广东省广州市黄埔区归谷科技园C2栋21层</span>
        </div>
        <div>
          <div>邮编：</div>
          <span>510000</span>
        </div>
        <div>
          <div>邮箱：</div>
          <span>business@xunlongai.com</span>
        </div>
        <div>
          <div>电话：</div>
          <span>020-32030256</span>
        </div>
        <div>
          <div>传真：</div>
          <ul>
            <li>020-32030256</li>
          </ul>
        </div>
        <div>
          <div>网址：</div>
          <span>www.ai.zkxunlong.com</span>
        </div>
        <!-- <div>
              <div>QQ：</div>
              <span>409422682</span>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import frameContent from '@/components/frameContent.vue'
export default {
  // components: {
  //   frameContent
  // },
  mounted () {
    this.$store.commit('totalChange', { isType: false, isHome: false })
  }
}
</script>

<style scoped lang="less">
.app-contact {
  // max-width: 1920px;
  min-width: 890px;
  height: 40.4rem;
  background-image: url("../assets/backgroundImage.png");
  background-repeat: no-repeat;
  background-size: 100% 40.4rem;
  .contact-box {
    max-width: 1180px;
    min-width: 895px;
    margin: 0 auto;
    padding-top: 230px;
  }
  .contact-title {
    text-align: left;
    margin:0px 0px 17px;
    font-size: 76px;
    font-weight: 600;
    color: #ffffff;
    line-height: 107px;
  }
  .phone {
    padding: 16px 35px 16px 20px;
    width: 555px;
    height: 252px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      span {
      }
      ul li {
        text-align: left;
      }
    }
  }
}
</style>
