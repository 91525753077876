<template>
  <div class="Innovate-two">
    <div class="Image"><img src="@/assets/Innovate/two.png" alt=""></div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
@import "../index.css";
.Innovate-two{
.Image {
    text-align: left;
    img {
      max-width: 930px;
      height: 454px;
      object-fit: cover;
    }
  }
}
</style>
