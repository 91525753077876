<template>
  <div class="app-About">
    <frameContent>
      <!-- 中部 -->
      <template #center>
        <div class="About-center">
          <div class="About-Tabs">
            <div class="tabs-left">
              <ul>
                <li
                  :class="{ active: active === 'one' }"
                  @click="tabsChange('one')"
                >
                  公司介绍
                </li>
                <li
                  :class="{ active: active === 'two' }"
                  @click="tabsChange('two')"
                >
                  荣誉资质
                </li>
              </ul>
            </div>
            <div class="tabs-content">
              <component :is="active"></component>
            </div>
          </div>
        </div>
      </template>
    </frameContent>
  </div>
</template>

<script>
import frameContent from '@/components/frameContent.vue'
import one from '@/components/About/one.vue'
import two from '@/components/About/two.vue'

export default {
  components: {
    frameContent,
    one,
    two
  },
  mounted () {
    this.$store.commit('totalChange', { isType: false, isHome: false })
  },
  data () {
    return {
      active: 'one'
    }
  },
  methods: {
    tabsChange (val) {
      this.active = val
    }
  }
}
</script>

<style scoped lang="less">
.About-center {
  padding-top: 42px;
 min-height: 646px;
  .About-Tabs {
    display: flex;
  }
  .tabs-left {
    margin-right: 70px;
      font-size: 14px;
    width: 180px;
    min-width: 180px;
    height: 379px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 12px 0px;
    ul li {
        cursor: pointer;
      padding: 6px 14px 6px 12px;
      margin-bottom: 8px;
      text-align: left;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    ul .active {
      background: #f9f9f9;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .tabs-content {
    flex: 1;
  }
}
</style>
