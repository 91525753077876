<template>
  <div class="Product-Campus">
    <div class="Campus-article">
      <div class="Product-Bananer">
        <img src="@/assets/Park/Banner.png" alt="" />
      </div>
    </div>
    <div>
      <div class="Product-Icon">
        <img src="@/assets/Park/Content.png" alt="" />
      </div>
    </div>
    <div>
      <div class="Product-content">
        <img src="@/assets/Park/Content2.png" alt="" />
      </div>
    </div>
    <div>
      <div class="Product-Image">
        <img src="@/assets/Park/Content3.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Park',
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
    this.$store.commit('totalChange', { isType: true, isHome: true })
  },
  computed: {
    ...mapGetters(['isType', 'isHome'])
  },
  methods: {
    scrollToTop () {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 0) {
        if (this.isHome && this.isType) {
          this.$store.commit('totalChange', { isType: false, isHome: false })
        }
      } else {
        this.$store.commit('totalChange', { isType: true, isHome: true })
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollToTop)
  }
}
</script>

<style lang="less" scoped>
.Product-Campus {
  .Campus-article {
    position: relative;
    height: 654px;
    .Product-Bananer {
      position: absolute;
      width: 100%;
      height: 760px;
      top: -107px;
      z-index: 1;
      img {
        width: 100%;
        height: 760px;
        object-fit: cover;
      }
    }
  }
  .Product-Icon {
    img {
      height: 374px;
    }
  }
  .Product-content {
    img {
      height: 1303px;
    }
  }
  .Product-Image {
    img {
      height: 667px;
    }
  }
  div:nth-child(3) {
    background: #f9fafc;
  }

  div:nth-child(n + 2) > div {
    max-width: 1180px;
    min-width: 895px;
    margin: 0 auto;
    padding: 80px 0px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
