<template>
  <div class="Innovate-one">
    <!-- <div class="title">动态光散射（DLS）技术</div>
    <p>
      该技术通过分析由纳米颗粒的布朗运动所引起的随机涨落光散射信号，得到运动颗粒信息的一种技术。由于胶体溶液中的纳米颗粒做布朗运动，当它们通过入射光束时会向四面八方产生光散射。
    </p>
    <br />
    <p>
      如果颗粒粒径较小，它布朗运动的扩散系数就会较大，相应地，它的散射光强涨落变化就会较快；反之则反。
    </p>
    <br />
    <p>
      在DLS技术中，光子相关器是能够快速准确表征散射光强涨落变化快慢的关键装置。通过对相关曲线的时域分析，结合反演算法就得到样品的粒径和粒径分布结果。
    </p> -->
    <div class="Image"><img src="@/assets/Innovate/one.png" alt=""></div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
@import "../index.css";
.Innovate-one{
.Image {
    text-align: left;
    img {
      max-width: 930px;
      height: 454px;
      object-fit: cover;
    }
  }
}
</style>
