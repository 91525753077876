import { render, staticRenderFns } from "./Banner1.vue?vue&type=template&id=c2015a74&scoped=true&"
import script from "./Banner1.vue?vue&type=script&lang=js&"
export * from "./Banner1.vue?vue&type=script&lang=js&"
import style0 from "./Banner1.vue?vue&type=style&index=0&id=c2015a74&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2015a74",
  null
  
)

export default component.exports