var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{ 'app-header': true, 'bgc-none': _vm.isHome }},[_c('frameContent',{scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{class:{ 'header-center': true }},[_c('div',{class:['logo', _vm.isType ? 'logo-type' : ''],attrs:{"title":"中科讯龙"},on:{"click":function($event){return _vm.NavChange('Home')}}}),_c('div',{staticClass:"header-nav"},[_c('div',{class:['phone', _vm.isType ? 'phone-type' : '']},[_vm._v(" 热线电话：020-32030256 ")]),_c('ul',{class:['ul-tab', _vm.isType ? 'ul-tab-type' : '']},[_c('li',{class:{ active: _vm.ActiveName === 'Home' },on:{"click":function($event){return _vm.isChange('Home')}}},[_vm._v(" 首页 "),_c('i')]),_c('li',{class:{
                active:
                  _vm.ActiveName === 'Product' ||
                  _vm.ActiveName === 'Sports' ||
                  _vm.ActiveName === 'Park' ||
                  _vm.ActiveName === 'Campus' ||
                  _vm.ActiveName === 'Banner1',
              },on:{"click":function($event){return _vm.isChange('Campus')}}},[_vm._v(" 解决方案 "),_c('i'),_c('ol',[_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.isChange('Campus')}}},[_c('div',[_c('h3',[_vm._v("智慧校园解决方案")]),_c('span',[_vm._v("通过智慧校园AI系统，进行校园安防、访客管理等业务管理")])])]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.isChange('Park')}}},[_c('div',[_c('h3',[_vm._v("智慧交通解决方案")]),_c('span',[_vm._v("保障交通安全、发挥交通基础设施效能、提高交通系统运行效率和管理水平")])])]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.isChange('Sports')}}},[_c('div',[_c('h3',[_vm._v("智慧体育解决方案")]),_c('span',[_vm._v("推进全民健身计划，推动全民健身智慧化发展，推进“互联网+健身”")])])])])]),_c('li',{class:{ active: _vm.ActiveName === 'Innovate' },on:{"click":function($event){return _vm.isChange('Innovate')}}},[_vm._v(" 技术创新 "),_c('i')]),_c('li',{class:{ active: _vm.ActiveName === 'About' },on:{"click":function($event){return _vm.isChange('About')}}},[_vm._v(" 关于我们 "),_c('i')]),_c('li',{class:{ active: _vm.ActiveName === 'Case' },on:{"click":function($event){return _vm.isChange('Case')}}},[_vm._v(" 经典案例 "),_c('i')]),_c('li',{class:{ active: _vm.ActiveName === 'Contact' },on:{"click":function($event){return _vm.isChange('Contact')}}},[_vm._v(" 联系我们 "),_c('i')])])])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }