<template>
  <div class="Innovate-four">
      <div class="Image"><img src="@/assets/Innovate/four.png" alt=""></div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
@import "../index.css";
.Innovate-four{
.Image {
    text-align: left;
    img {
      max-width: 930px;
      height: 454px;
      object-fit: cover;
    }
  }
}
</style>
