<template>
  <div id="app">
    <Header
      :isType="isType"
    ></Header>
    <component ref="tab" :is="ActiveName" @typeChange="typeChange"></component>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import About from '@/views/About.vue'
import Home from '@/views/Home.vue'
import Product from '@/views/Product/index.vue'
import Innovate from '@/views/Innovate.vue'
import Case from '@/views/Case.vue'
import Contact from '@/views/Contact.vue'
import Campus from '@/views/Product/Campus.vue'
import Park from '@/views/Product/Park.vue'
import Sports from '@/views/Product/Sports.vue'
import Banner1 from '@/views/BannerProduct/Banner1.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    Home,
    About,
    Product,
    Innovate,
    Case,
    Contact,
    Header,
    Footer,
    Campus,
    Park,
    Sports,
    Banner1
  },

  computed: {
    ...mapGetters(['isType', 'ActiveName'])
  },
  data () {
    return {
      // activeName: 'Campus'
    }
  },
  methods: {
    ActiveNamechange (val) {
      this.ActiveName = val
    },
    typeChange (val) {
      console.log(55577777, val)
    }
  },
  watch: {
    ActiveName: function (to, from) {
      this.$store.commit('totalChange', { isType: false, isHome: false })
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.header-static {
  position: sticky;
  top: 10px;
}
</style>
