<template>
  <div :style="msg">
    <!-- <div><slot name="left"></slot></div> -->
    <div class="app-content"><slot name="center"></slot></div>
    <!-- <div><slot name="right"></slot></div> -->
  </div>
</template>

<script>
export default {
  name: 'frameContent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.app-content {
  max-width: 1180px;
  margin: 0 auto;
  min-width: 895px;
}
</style>
