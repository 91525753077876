<template>
  <div class="Innovate-one">
    <div class="title">荣誉资质</div>
    <div class="Image"><img src="@/assets/About/Image1.png" alt="" /></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@import "../index.css";
.Innovate-one {
  .Image {
    text-align: left;
    img {
      max-width: 755px;
      height: 456px;
      object-fit: cover;
    }
  }
}
</style>
