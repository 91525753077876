<template>
  <div class="Innovate-one">
    <div class="title">公司介绍</div>
    <div class="content">
      <div>
        <h3>讯龙（广东）智能科技有限公司</h3>，致力于通过人工智能创新中的机器视觉和深度学习技术积累，为传统制造业赋能，提升公共服务水平。
      </div>
      <br />
      <div>
        在机器视觉领域中，我司已经创新性地发展出了<h3>人脸识别、姿态识别、瑕疵识别，以及危险动作识别、表情分析和轨迹分析</h3>等关键技术。在智慧教育、智慧体育、智慧安防，以及智慧交通、智慧养老和智慧旅游等领域积累了数十项专利及科研成果。
      </div>
      <br />
      <div>
        我司依托自身科研成果与行业深度结合，催生出了一大批代表新技术和新经济的信息化产品和解决方案，助力大湾区的智能化升级。
      </div>
      <br />
      <div>
        在大湾区建设的宏图中，本司联合华南师范大学、北京师范大学和中科院等科研院所，建立产学研平台，深度探索校企合作在人工智能领域的知识成果创新转化。并与华为、百度、阿里云等行业先锋企业建立紧密的合作关系。
      </div>
      <br />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
@import "../index.css";
.content {
  text-align: left;
  div {
    text-indent: 2em;
    font-size: 15px;
  }
  h3 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
    display: inline;
  }
}
</style>
