const home = {
  state: {
    isType: false,
    ActiveName: 'Home',
    isHome: true
  },
  mutations: {
    totalChange (state, val) {
      state.isType = val.isType
      if (val.ActiveName) {
        state.ActiveName = val.ActiveName
      }
      state.isHome = val.isHome
    },
    typeChange (state, val) {
      state.isType = val
    },
    ActiveNameChange (state, val) {
      state.ActiveName = val
    },
    HomeChange (state, val) {
      state.isHome = val
    }
  }

}

export default home
