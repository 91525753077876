<template>
  <div class="Product-Campus">
    <div class="Product-Image">
      <img src="@/assets/Banner1/Image1.png" alt="" />
    </div>
    <div class="Product-Image">
      <img src="@/assets/Banner1/Image2.png" alt="" />
    </div>
    <div class="Product-Image">
      <img src="@/assets/Banner1/Image3.png" alt="" />
    </div>
    <div class="Product-Image">
      <img src="@/assets/Banner1/Image4.png" alt="" />
    </div>
    <div class="Product-Image">
      <img src="@/assets/Banner1/Image5.png" alt="" />
    </div>
    <div class="Product-Image">
      <img src="@/assets/Banner1/Image6.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner1',
  mounted () {
    this.$store.commit('totalChange', { isType: false, isHome: false })
  }
}
</script>

<style lang="less" scoped>
.Product-Campus {
  padding-bottom: 40px;
  div {
    max-width: 1180px;
    min-width: 895px;
    height: 492px;
    margin: 40px auto 0px;
    img {
      max-width: 1180px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
