<template>
  <div class="app-case">
    <frameContent>
      <!-- 中部 -->
      <template #center>
        <div class="case-center">
          <div class="case-center-image">
            <ul>
              <li v-for="(item, index) in dataList" :key="index">
                <img :src="item.url" alt="" /><span>{{item.name}}</span>
              </li>
            </ul>
          </div>
          <div class="case-center-pagination">
            <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" :page-size="size" @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </template>
    </frameContent>
  </div>
</template>

<script>
import frameContent from '@/components/frameContent.vue'
import Image1 from '@/assets/Case/Image1.png'
import Image2 from '@/assets/Case/Image2.png'
import Image3 from '@/assets/Case/Image3.png'
import Image4 from '@/assets/Case/Image4.png'
import Image5 from '@/assets/Case/Image5.png'
import Image6 from '@/assets/Case/Image6.png'
// import Image7 from '@/assets/Case/Image7.png'
import Image8 from '@/assets/Case/Image8.png'
import Image9 from '@/assets/Case/Image9.png'
// import Image10 from '@/assets/Case/Image10.png'

export default {
  components: {
    frameContent
  },
  data () {
    return {
      dataList: [],
      allData: [
        { id: 0, url: Image1, name: '广雅中学' },
        { id: 1, url: Image2, name: '广州市第二中学' },
        { id: 2, url: Image3, name: '广州广雅实验学校' },
        { id: 3, url: Image4, name: '南沙多所幼儿园' },
        { id: 4, url: Image5, name: '广州开元学校' },
        { id: 5, url: Image6, name: '华南农业大学珠江学院' },
        { id: 6, url: Image8, name: '广州市陈嘉庚纪念中学' },
        { id: 7, url: Image9, name: '广州市协和中学' }
        // { id: 6, url: Image7, name: '广州黄埔广附实验学校' },
        // { id: 9, url: Image10, name: '广州市荔湾区体育实验小学' }
      ],
      total: 0,
      page: 1,
      size: 6
    }
  },
  mounted () {
    this.getDataList()
    this.$store.commit('totalChange', { isType: false, isHome: false })
  },
  methods: {
    getDataList () {
      this.dataList = this.allData.slice((this.page - 1) * this.size, this.page * this.size)
      this.total = this.allData.length
    },
    currentChange (val) {
      console.log(val)
      this.page = val
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.case-center {
min-width: 890px;
  margin-top: 50px;
  .case-center-image {
    margin-bottom: 17px;
    ul {
      display: flex;
      flex-wrap: wrap;
      min-height: 516px;
      // justify-content: space-between;
      li {
        margin-right: 20px;
        position: relative;
        margin-bottom: 18px;
        width: 380px;
        height: 240px;
        background: #cccccc;
        border-radius: 4px;
        &:nth-child(3n){
        margin-right: 0px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        span {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 44px;
          background: rgba(0, 0, 0, 0.8);
          border-radius: 0px 0px 4px 4px;
          line-height: 44px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .case-center-pagination {
    text-align: right;
    margin-bottom: 35px;
  }
}
/deep/.case-center .el-pagination {
  padding: 0px;
}
/deep/.el-pagination.is-background .btn-next {
  margin: 0px;
}
</style>
